import $http from "@/plugins/axios.js"

export default {
    state:{
        computer:{
            data:[]
        }
    },
    getters:{
        computer: state => state.computer
    },
    actions:{
        async getComputers({commit} , filterData = {take:15,page:1,name:'',}){
            const response = await $http.get(`/order-computer?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}`)
            commit("SET_COMPUTERS", response.data);
        },

        async createComputer({commit},computer){
            const response = await $http.post('/computer',computer)
            commit('ADD_NEW_COMPUTER',response.data.data)
        },

        async updateComputer({commit} , computer){
            const response = await $http.post(`/computer/${computer.id}`,computer)
            commit('UPDATE_COMPUTER',response.data.data)
        },
        async removeComputer({commit} , id){
            await $http.delete(`/computer/${id}`)
            commit('REMOVE_COMPUTER',id)
        }
    },
    mutations:{
        SET_COMPUTERS(state,data){
            state.computer = data
        },
        ADD_NEW_COMPUTER(state,data){
            state.computer.data.push(data)
        },
        UPDATE_COMPUTER(state,data){
            state.computer.data  = state.computer.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_COMPUTER(state,id){
            state.computer.data  = state.computer.data.filter(item=> item.id !== id)
        },
        
    }
}