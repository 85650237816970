import $http from "@/plugins/axios.js"

export default {
    state:{
        jeks:[],
        jek:{}
    },
    getters:{
        jeks: state => state.jeks
    },
    actions:{
        async getJeks({commit} , filterData = {take:15,page:1,name:'',}){
            const response = await $http.get(`/order-jek?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}`)
            commit("SET_JEKS", response.data);
        },

        async getJek({commit} , id){
            const response = await $http.get(`/jek/${id}`)
            commit("SET_JEK", response.data);
        },

        async createJek({commit},jek){
            const response = await $http.post('/jek',jek)
            commit('ADD_NEW_JEK',response.data.data)
        },

        async updateJek({commit} , jek){
            await $http.post(`/jek/${jek.id}`,jek)
            return commit 
        },
        async removeJek({commit} , id){
            await $http.delete(`/jek/${id}`)
            commit('REMOVE_JEK',id)
        }
    },
    mutations:{
        SET_JEKS(state,data){
            state.jeks = data
        },
        SET_JEK(state,data){
            state.jek = data
        },
        ADD_NEW_JEK(state,data){
            state.jeks.data.push(data)
        },
        UPDATE_JEK(state,data){
            state.jeks.data  = state.jeks.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_JEK(state,id){
            state.jeks.data  = state.jeks.data.filter(item=> item.id !== id)
        },
        
    }
}