import $http from "@/plugins/axios.js"

export default {
    state:{
        users:[]
    },
    getters:{
        users: state => state.users
    },
    actions:{
        async getUsres({commit} , filterData = {take:15,skip:0,email:'',name:""}){
            const response = await $http.get(`/user?take=${filterData.take}&skip=${filterData.skip}&filter[email]=${filterData.email}&filter[name]=${filterData.name}`)
            commit("SET_USERS", response.data);
        },

        async createUser({commit},user){
            const response = await $http.post('/user',user)
            commit('ADD_NEW_USER',response.data.data)
        },

        async updateUser({commit} , user){
            const response = await $http.post(`/user/${user.id}`,user)
            commit('UPDATE_USER',response.data.data)
        },
        async removeUser({commit} , id){
            await $http.delete(`/user/${id}`)
            commit('REMOVE_USER',id)
        }
    },
    mutations:{
        SET_USERS(state,data){
            state.users = data
        },
        ADD_NEW_USER(state,data){
            state.users.data.push(data)
        },
        UPDATE_USER(state,data){
            state.users.data  = state.users.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_USER(state,id){
            state.users.data  = state.users.data.filter(item=> item.id !== id)
        },
        
    }
}