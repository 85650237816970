import Vue from 'vue'
import Vuex from 'vuex'
import users from "./modules/Users"
import permissions from "./modules/Permissions"
import Roles from "./modules/Roles.js"
import auth from "./modules/auth.js"
import orders from "./modules/Orders.js"
import daino from "./modules/Daino.js"
import Computer from "./modules/Computer.js"
import Jek from "./modules/Jek.js"
import Cover from "./modules/Cover.js"
import Accountant from "./modules/Accountant.js"

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    editStatus:false,
    sideBarStatus:true,
    loadingData:false,

    getLoader:false,
    postLoader:false,


  },
  getters:{
    editStatus:state => state.editStatus,
    sideBarStatus:state => state.sideBarStatus,
    getLoader: state => state.getLoader,
    postLoader: state => state.postLoader,
    
  },
  mutations: {
    toggleEditStatus(state) {
      state.editStatus = !state.editStatus
    },
    toggleSideBarStatus(state) {
      state.sideBarStatus = !state.sideBarStatus
    },
  },
  actions: {
  },
  modules: {
    auth,
    users,
    orders,
    daino,
    Computer,
    Jek,
    Cover,
    permissions,
    Roles,
    Accountant
  }
})
