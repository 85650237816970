<template>
  <div id="OrderImage" class="pb-8">
    <min-nav></min-nav>
    <page-header title=" رواتب الموظفين" icon="fas fa-file-image"></page-header>
    <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
      <div class="container">
        <div class="container">
          <div class="section-title">
            <div v-if="SalariesData.length" class="text-lg mt-2">
              <span v-if="SalariesData[0].employee">
                قائمة رواتب
                {{ SalariesData[0].employee.name }}
              </span>
            </div>
            <span>
              <router-link :to="'/accountant/1'">
                <div class="mt-9 cursor-pointer">
                  <span
                    :class="[
                      postLoader ? 'opacity-60 cursor-not-allowed' : '',
                      'btn sub-btn',
                    ]"
                    :disabled="postLoader"
                  >
                    رجوع
                    <i style="margin-right: 0.5rem" class="fas fa-arrow-left"></i>
                  </span>
                </div>
              </router-link>
            </span>
          </div>
        </div>
      </div>
      <div class="">
        <div class="container mt-10">
          <div class="section-title">
            <span class="text-xl">قائمة الرواتب السابقة</span>
            <div class="show-per-Page text-sm font-normal">
              <span class=""> عناصر القائمة </span>
              <select
                name=""
                id=""
                v-model="filterData.take"
                class="bg-gray-100 px-2 rounded-md outline-none"
                @change="filtering"
              >
                <option :value="2">2</option>
                <option :value="15">15</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
                <option :value="500">500</option>
              </select>
            </div>
          </div>
          <div class="list relative py-5">
            <get-loader class="absolute inset-0"></get-loader>
            <data-table :fields="SalariesFields" :data="SalariesData">
              <template v-slot:employee="{ item }">
                {{ item.employee?.split("-")[4] }}
              </template>
              <template v-slot:name="{ item }">
                {{ item.employee.name }}
              </template>
              <template v-slot:created_at="{ item }">
                {{ getFormDate(item.created_at) }}
              </template>
            </data-table>
          </div>
        </div>
      </div>
      <div
        class="pagination mx-auto py-4 bg-white mt-3"
        v-if="salary.total > filterData.take"
      >
        <div class="container flex items-center justify-center">
          <v-pagination
            v-model="currentPage"
            :records="salary.total"
            :per-page="filterData.take"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      SalariesData: [],
      filterData: {
        id: "",
        take: 15,
        page: 1,
      },
    };
  },
  computed: {
    SalariesFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "مقدار دفعة الراتب",
          key: "amount",
        },
        {
          title: "تاريخ الدفع",
          key: "created_at",
        },
      ];
    },
  },
  watch: {
    currentPage(value) {
      this.filterData.page = value;
      this.getSalary(this.filterData).then(() => {
        this.SalariesData = this.salary.data;
      });
    },
  },
  methods: {
    filtering() {
      this.getSalary(this.filterData).then(() => {
        this.SalariesData = this.salary.data;
      });
    },
  },
  mounted() {
    this.filterData.id = this.$route.params.id;
    this.getSalary(this.filterData).then(() => {
      this.SalariesData = this.salary.data;
    });
  },
};
</script>
