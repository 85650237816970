<template>
  <div class="table-content">
    <!-- <div class="loading" v-if="$store.state.loading">
      <img src="@/assets/imgs/loading.gif" width="75" class="mx-auto" alt="" />
    </div> -->
    <table id="myTable" class="w-full over" v-if="data.length !== 0">
      <thead class="text-right text-gray-500">
        <th class="pl-4 border-b-2" v-if="num">#</th>
        <th class="border-b-2" v-for="(item, index) in fields" :key="index">
          {{ item.title }}
        </th>
        <th class="border-b-2" v-if="actions">تعديل</th>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in data"
          :key="item.id"
          class="odd:bg-white even:bg-gray-100 duration-500 text-md"
        >
          <td class="font-bold lg:pl-4 border-b py-0 md:py-3" v-if="num">
            {{ index + 1 }} -
          </td>
          <template v-for="(field, index) in fields">
            <td class="font-bold lg:pl-4 border-b py-0 md:py-3" :key="index">
              <slot :name="field.key" :item="item">
                {{ item[field.key] }}
              </slot>
            </td>
          </template>
          <td v-if="actions" class="font-bold lg:pl-4 border-b py-0 md:py-3">
            <slot name="actions" class="" :item="item">
              <span class="text-gray-400 font-semibold">No Action</span>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="hint opacity-70"
      v-if="!$store.state.loading && data.length == 0"
    >
      <img src="" class="w-56 mx-auto" />
      <h1 class="font-bold text-gray-500 text-center text-2xl">
        <span> لاتــوجد بيانات متوفرة حالية </span>
        <span>
          <i class="far fa-folder-open"></i>
        </span>
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["edit", "acceptMember", "reject", "deletItem", "filters"],
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    fields: {
      default: () => [],
      type: Array,
    },
    num: {
      default: true,
      type: Boolean,
    },
    actions: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      test: "Mof",
    };
  },
};
</script>
<style>
#myTable td,
#myTable th {
  @apply px-3;
}
</style>
