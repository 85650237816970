<template>
  <div id="sectionPrint ">
    <div class="container">
      <invoice-header
        :orderInfo="data"
        :class="hidden ? 'print:flex hidden' : ''"
        class="mb-5"
      ></invoice-header>
      <div class="section-info">
        <div class="pt-8 mb-8">
          <div class="rounded-md p-2 border border-gray-300 relative text-sm">
            <div
              class="title font-bold mb-5 absolute -top-3 px-5 right-5 bg-indigo-50"
            >
              {{ sectionTitle }}
            </div>
            <div class="header grid grid-cols-2">
              <ul class="">
                <li
                  v-for="(title, index) in dataSection"
                  :key="index"
                  class="border-b pb-2 font-bold text-sm text-gray-500"
                >
                  {{ title.title }}
                </li>
              </ul>
              <ul class="">
                <li
                  v-for="(title, index) in dataSection"
                  :key="index"
                  class="border-b pb-2 text-sm font-bold text-gray-500"
                >
                  <span v-if="data[section][title.key] == null"> - </span>
                  <span v-else-if="data[section][title.key] == false">
                    لايوجد
                  </span>
                  <span v-else-if="data[section][title.key] == true">
                    نعم
                  </span>
                  <span v-else> {{ data[section][title.key] }} </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="notes bg-gray-100 mt-3 py-2 px-2 rounded-md text-xs"
            v-if="data[section].description"
          >
            <span class="block font-bold text-red-400"> الملاحضات : </span>
            <span class="font-semibold text-red-400">
              {{ data[section].description }}
            </span>
          </div>
          <ul
            class="flex items-center gap-3 section-img pt-3"
            v-if="section == 'jek'"
          >
            <li class="w-3/5">
              <img src="@/assets/print_imgs/jek/01.jpg" alt="" />
            </li>
            <li class="w-3/5">
              <img src="@/assets/print_imgs/jek/03.jpg" alt="" />
            </li>
            <li class="w-3/5">
              <img src="@/assets/print_imgs/jek/02.jpg" alt="" />
            </li>
          </ul>
          <ul class="flex items-center gap-3 section-img pt-3" v-else>
            <li class="w-3/5">
              <img src="@/assets/print_imgs/daino/01.jpg" alt="" />
            </li>
            <li class="w-3/5">
              <img src="@/assets/print_imgs/daino/03.jpg" alt="" />
            </li>
            <li class="w-3/5">
              <img src="@/assets/print_imgs/daino/02.jpg" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import invoiceHeader from "./OrderInfo";

export default {
  props: {
    data: Object,
    section: String,
    dataSection: Array,
    imgs: Array,
    sectionTitle: String,
    hidden: Boolean,
  },
  components: {
    invoiceHeader,
  },
};
</script>
