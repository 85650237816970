<template>
  <div id="ServicesPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="الصلاحيات" icon="fas fa-users"></page-header>

    <div
      v-if="checkRole(['update-permission'])"
      :class="[editStatus ? 'active' : '', 'edit-form']"
    >
      <form
        action=""
        class="grid grid-cols-1 gap-1 w-11/12 md:w-8/12 bg-white rounded px-3 py-5 max-h-full overflow-auto"
      >
        <div class="input">
          <label for="" class=""> أسم الصلاحية </label>
          <input
            type="text"
            placeholder="الاسم"
            v-model="editData.name"
            class="field"
          />
        </div>

        <div class="controll flex justify-end pt-8">
          <button class="btn sub-btn" @click.prevent="toggleEditStatus()">
            غلق
          </button>
          <button
            :class="[
              postLoader ? 'opacity-60 cursor-not-allowed' : '',
              'btn main-btn',
            ]"
            :disabled="postLoader"
            @click="edit_item()"
          >
            تعديل
          </button>
        </div>
      </form>
    </div>
    <div
      v-if="checkRole(['store-permission'])"
      class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3"
    >
      <div class="container">
        <div class="section-title">
          <span class="text-xl">اضافة صلاحيات</span>
        </div>
        <form action="" class="grid grid-cols-1 gap-1">
          <div class="input">
            <label for="" class=""> أسم الصلاحية </label>
            <input
              type="text"
              placeholder="الاسم"
              v-model="postData.name"
              class="field"
            />
          </div>
          <div class="controll flex justify-end pt-8">
            <button
              :class="[
                postLoader ? 'opacity-60 cursor-not-allowed' : '',
                'btn main-btn',
              ]"
              :disabled="postLoader"
              @click="add_new_item()"
            >
              اضافة
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="list-form" v-if="checkRole(['get-permission'])">
      <div class="container">
        <div class="section-title">
          <span class="text-xl">صلاحيات المستخدمين</span>
          <div class="show-per-Page text-sm font-normal">
            <span class=""> عناصر القائمة </span>
            <select
              name=""
              id=""
              v-model="filterData.take"
              class="bg-gray-100 px-2 rounded-md outline-none"
              @change="filtering"
            >
              <option value="15">15</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <div class="filters" @change="filtering()">
          <div class="name input">
            <input
              type="search"
              placeholder="عنوان الصلاحية"
              class="field"
              v-model="filterData.name"
            />
          </div>
        </div>
        <div class="list relative py-5">
          <get-loader class="absolute inset-0"></get-loader>
          <data-table
            :fields="fields"
            :data="permissions.data"
            :actions="false"
          >
            <template v-slot:created_at="{ item }">
              {{ getFormDate(item.created_at) }}
            </template>
            <template v-slot:updated_at="{ item }">
              {{ getFormDate(item.updated_at) }}
            </template>
            <!-- <template v-slot:actions="{ item }">
              <ul class="actions-list">
                <li
                  class="text-gray-500 hover:text-blue-500"
                  @click="editInit(item)"
                >
                  <i class="fas fa-pencil-alt"></i>
                </li>
                <li
                  class="text-gray-500 hover:text-red-500"
                  @click="remove_item(item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </li>
              </ul>
            </template> -->
          </data-table>
        </div>
      </div>
    </div>
    <div
      class="pagination w-11/12 mx-auto py-4 bg-white mt-3"
      v-if="permissions.total > filterData.take"
    >
      <div class="container flex items-center justify-center">
        <v-pagination
          v-model="currentPage"
          :records="permissions.total"
          :per-page="filterData.take"
        />
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      postData: {
        name: "",
      },
      editData: {},
      filterData: {
        take: 15,
        page: 1,
        name: "",
      },
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "عنوان الصلاحية",
          key: "name",
        },
        {
          title: "الاضافة",
          key: "created_at",
        },
        {
          title: "التعديل",
          key: "updated_at",
        },
      ];
    },
  },
  watch: {
    currentPage(value) {
      this.filterData.page = value;
      this.getPermissions(this.filterData);
    },
  },
  methods: {
    filtering() {
      this.getPermissions(this.filterData);
    },
    add_new_item() {
      this.createPermission(this.postData).then(() => {
        this.postData = {
          name: "",
        };
      });
    },
    editInit(item) {
      this.toggleEditStatus();
      Object.assign(this.editData, item);
      this.editData._method = "PUT";
    },
    edit_item() {
      delete this.editData.last_login;
      this.updatePermission(this.editData).then(() => {
        this.toggleEditStatus();
      });
    },
    remove_item(item) {
      this.confirmDelete(() => {
        this.removePermission(item.id);
      });
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>
