<template>
  <div id="ServicesPage" class="cover-page pb-16">
    <min-nav></min-nav>
    <page-header title="فحص البدي" icon="fas fa-briefcase"></page-header>
    <div
      v-if="checkRole(['store-Cover'])"
      class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3 relative"
    >
      <get-loader class="absolute inset-0 z-10"></get-loader>
      <div class="container grid grid-cols-1 lg:grid-cols-3">
        <div
          class="car-img lg:col-start-1 lg:col-end-2 p-3 bg-opacity-25 bg-cover bg-center flex items-center justify-center"
        >
          <salon v-if="order.car_body_type == 0" :coverInfo="postData" />
          <pickup v-else-if="order.car_body_type == 1" :coverInfo="postData" />
          <van v-else-if="order.car_body_type == 2" :coverInfo="postData" />
          <gxr v-else-if="order.car_body_type == 3" :coverInfo="postData" />
        </div>
        <div
          class="car-details lg:col-start-2 lg:col-end-4 bg-gray-100 rounded p-4"
        >
          <div class="car-info">
            <h1 class="text-2xl">{{ order.driver_name }}</h1>
            <ul class="flex items-center gap-3 py-2 text-gray-500">
              <li class="">
                <span class="icon">
                  <i class="fas fa-calendar-day"></i>
                </span>
                <span class="value px-1"> {{ order.car_model }} </span>
              </li>
              <li class="">
                <span class="icon">
                  <i class="fas fa-copyright"></i>
                </span>
                <span class="value px-1"> {{ order.car_type }} </span>
              </li>
            </ul>
          </div>
          <div class="car-peace-details grid grid-cols-2 gap-3 pt-8">
            <h1
              class="text-sm text-gray-800 font-bold col-span-full pb-3 border-b"
            >
              <span class="pl-2">
                <i class="fa-solid fa-spinner"></i>
              </span>
              <span> حالة قطع المركبة </span>
            </h1>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> البنيد </label>
              <select v-model="postData.hood">
                <option value="" selected disabled>البنيد</option>
                <!-- <option value="">مستبدل</option> -->
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> سقف </label>
              <select v-model.number="postData.roof">
                <option value="" selected disabled>البنيد</option>
                <!-- <option value="">مستبدل</option> -->
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              الدعامية
            </h2>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                الدعامية الامامية
              </label>
              <select class="baned" v-model="postData.front_bumper">
                <option value="" selected disabled>الدعامية الامامية</option>
                <!-- <option value="">مستبدل</option> -->
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> دعامية خلفي </label>
              <select v-model="postData.back_bumper">
                <option value="" selected disabled>دعامية خلفي</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              الجاملغات
            </h2>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                جاملغ امامي أيسر
              </label>
              <select v-model="postData.left_side_cover_front">
                <option value="" selected disabled>جاملغ امامي أيسر</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                جاملغ امامي ايمن
              </label>
              <select v-model="postData.right_side_cover_front">
                <option value="" selected disabled>جاملغ امامي ايمن</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                جاملغ خلفي ايسر
              </label>
              <select v-model="postData.left_side_cover_back">
                <option value="" selected disabled>جاملغ خلفي ايسر</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                جاملغ خلفي ايمن
              </label>
              <select v-model="postData.right_side_cover_back">
                <option value="" selected disabled>جاملغ خلفي ايمن</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              الابواب
            </h2>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                باب امامي ايسر
              </label>
              <select v-model="postData.left_door_front">
                <option value="" selected disabled>باب امامي ايسر</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                باب امامي ايمن
              </label>
              <select v-model="postData.right_door_front">
                <option value="" selected disabled>باب امامي ايمن</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                باب خلفي ايسر
              </label>
              <select v-model="postData.left_door_back">
                <option value="" selected disabled>باب خلفي ايسر</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                باب خلفي ايمن
              </label>
              <select v-model="postData.right_door_back">
                <option value="" selected disabled>باب خلفي ايمن</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              التكم
            </h2>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                تكمة امامي ايسر
              </label>
              <select v-model="postData.left_front_techma">
                <option value="" selected disabled>تكمة امامي ايسر</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                تكمة امامي ايمن
              </label>
              <select v-model="postData.right_front_techma">
                <option value="" selected disabled>تكمة امامي ايمن</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1">
                تكمة خلفي ايسر
              </label>
              <select v-model="postData.left_back_techma">
                <option value="" selected disabled>تكمة خلفي ايسر</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <div class="slect_container" v-if="order.car_body_type !== 2">
              <label for="" class="text-main text-sm mb-1">
                تكمة خلفي ايمن
              </label>
              <select v-model="postData.right_back_techma">
                <option value="" selected disabled>تكمة خلفي ايمن</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              جنطة
            </h2>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> جنطة </label>
              <select v-model="postData.Boot">
                <option value="" selected disabled>جنطة</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> باب جنطة </label>
              <select v-model="postData.Boot_door">
                <option value="" selected disabled>باب جنطة</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              زجاج
            </h2>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> زجاج أمامي </label>
              <select v-model="postData.windscreen_front">
                <option value="" selected disabled>زجاج أمامي</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> زجاج خلفي </label>
              <select v-model="postData.windscreen_back">
                <option value="" selected disabled>زجاج خلفي</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>

            <h2
              class="border-y-2 py-3 text-lg text-center col-span-full font-bold mt-3"
            >
              مري
            </h2>

            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> مرايا يسار </label>
              <select v-model="postData.left_mirror">
                <option value="" selected disabled>مرايا يسار</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="slect_container">
              <label for="" class="text-main text-sm mb-1"> مرايا يمين </label>
              <select v-model="postData.right_mirror">
                <option value="" selected disabled>مرايا يمين</option>
                <option value="0">سليم</option>
                <option value="1">مستبدل بلادي</option>
                <option value="2">قطعه مفقودة</option>
                <option value="3">مستبدل مصبوغ</option>
                <option value="4">تعديل بارد</option>
                <option value="5">قطعة مصبوغة</option>

                <option value="7">تكحيل</option>
                <option value="8">كلير</option>
                <option value="9">تحتوي على شخوط</option>
                <option value="10">رصعة</option>
                <option value="11">جراد</option>
              </select>
            </div>
            <div class="input mb-4 col-span-full">
              <label for="" class="requier"> وصف الحالة </label>
              <textarea
                placeholder="وصــف ...."
                v-model="postData.description"
                rows="6"
                class="field bg-white"
              ></textarea>
            </div>
          </div>
          <div class="controll flex justify-end">
            <button
              :class="[
                postLoader ? 'opacity-60 cursor-not-allowed' : '',
                'btn main-btn',
              ]"
              :disabled="postLoader"
              @click="add_new_item()"
            >
              <span v-if="typeRequest == 'post'"> اضافة </span>
              <span v-else> تعديل </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
import salon from "@/components/cars/salonType.vue";
import pickup from "@/components/cars/pickupType.vue";
import van from "@/components/cars/vanType.vue";
import gxr from "@/components/cars/gxrType.vue";
export default {
  data() {
    return {
      typeRequest: "post",
      postData: {
        hood: 0,
        roof: 0,
        windscreen_front: 0,
        windscreen_back: 0,
        right_mirror: 0,
        left_mirror: 0,
        front_bumper: 0,
        back_bumper: 0,
        right_side_cover_front: 0,
        left_side_cover_front: 0,
        right_side_cover_back: 0,
        left_side_cover_back: 0,
        right_front_techma: 0,
        left_front_techma: 0,
        right_back_techma: 0,
        left_back_techma: 0,
        Boot: 0,
        Boot_door: 0,
        right_door_front: 0,
        left_door_front: 0,
        right_door_back: 0,
        left_door_back: 0,
        order_id: "",
        description: "",
      },
    };
  },
  computed: {},
  components: {
    salon,
    pickup,
    van,
    gxr,
  },
  methods: {
    add_new_item() {
      if (this.typeRequest == "post") {
        this.createCover(this.postData).then(() => {
          this.$router.push("/cover-list");
        });
      } else {
        this.updateCover(this.postData).then(() => {
          this.$router.push("/cover-list");
        });
      }
    },
  },
  mounted() {
    this.postData.order_id = this.$route.params.id;
    this.getOrder({
      id: this.postData.order_id,
      include: "cover",
    }).then(() => {
      if (this.order.cover) {
        this.typeRequest = "edit";
        this.postData = this.order.cover;
        this.postData._method = "PUT";
      }
    });
  },
};
</script>
