<template>
  <div
    id="layoutPage"
    :class="[sideBarStatus ? 'md:pr-64' : 'pr-0', 'duration-700']"
  >
    <sidebar></sidebar>
    <router-view></router-view>
  </div>
</template>
<script>
import sidebar from "../components/Globle/sidebarSection.vue";
export default {
  data() {
    return {};
  },
  components: {
    sidebar,
  },
  mounted() {
    if (localStorage.getItem("car_user_Info") == null) {
      this.$router.push("/");
    }
  },
};
</script>
