<template>
  <div id="ServicesPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title=" صرفيات موقع الحبيبية" icon="fas fa-calculator"></page-header>
    <div class="add-form -mt-10 bg-white mx-auto py-4 rounded shadow mb-3">
      <div class="container">
        <div class="">
          <div class="container mt-10">
            <div class="section-title">
              <span class="text-xl">قائمةالصرفيات</span>
              <div class="show-per-Page text-sm font-normal">
                <span class=""> عناصر القائمة </span>
                <select
                  name=""
                  id=""
                  v-model="filterData.take"
                  class="bg-gray-100 px-2 rounded-md outline-none"
                  @change="filteringFinancials"
                >
                  <option :value="2">2</option>
                  <option :value="15">15</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                  <option :value="500">500</option>
                </select>
              </div>
            </div>
            <div class="filters">
              <div class="input mb-4">
                <!-- <label for="" class="requier"> التاريخ</label> -->
                <date-picker
                  @change="filtering()"
                  v-model="filterData.date"
                  valueType="format"
                  range
                ></date-picker>
              </div>
              <!-- <div class="name input">
                <input
                  type="search"
                  placeholder="اسم الموظف"
                  class="field"
                  v-model="filterData.name"
                />
              </div> -->
            </div>
            <div class="list relative py-5">
              <get-loader class="absolute inset-0"></get-loader>
              <data-table :fields="financialFields" :data="financials.data">
                <template v-slot:order_no="{ item }">
                  {{ item.order_no?.split("-")[4] }}
                </template>
                <template v-slot:name="{ item }">
                  {{ item.employee.name }}
                </template>
                <template v-slot:created_at="{ item }">
                  {{ getFormDate(item.created_at) }}
                </template>

                <template v-slot:updated_at="{ item }">
                  {{ getFormDate(item.updated_at) }}
                </template>
                <template v-slot:actions="{ item }">
                  <ul class="actions-list">
                    <!-- <li
                              v-if="item.status !== 4 && item.status !== 5"
                              aria-label="تعديل الراتب"
                              class="text-gray-500 hover:text-blue-500 hint--top"
                            >
                              <i class="fas fa-pencil-alt"></i>
                            </li> -->
                    <li
                      v-if="item.status !== 4 && item.status !== 5"
                      aria-label="مسح "
                      class="text-gray-500 hover:text-red-500 hint--top"
                      @click="delete_financial(item)"
                    >
                      <i class="fas fa-times"></i>
                    </li>
                  </ul>
                </template>
              </data-table>
            </div>
          </div>
        </div>
        <div
          class="pagination mx-auto py-4 bg-white mt-3"
          v-if="financials.total > filterData.take"
        >
          <div class="container flex items-center justify-center">
            <v-pagination
              v-model="currentPageFinancials"
              :records="financials.total"
              :per-page="filterData.take"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  /* eslint-disable */
  components: { DatePicker },
  data() {
    return {
      date: null,
      currentPage: 1,
      currentPageEmployees: 1,
      currentPagePunishment: 1,
      currentPageRewards: 1,
      currentPageFinancials: 1,
      currentPageLoans: 1,

      openTab: 1,
      mainTab: 1,
      loanTab: 1,
      editData: {},
      filterData: {
        take: 15,
        page: 1,
        skip: 0,
        name: "",
        date: "",
        date_from: "",
        date_to: "",
      },
    };
  },
  watch: {
    currentPageFinancials(value) {
      this.filterData.page = value;
      this.getFinancials(this.filterData);
    },
  },
  computed: {
    fields() {
      return [
        {
          title: "الاسم الكامل",
          key: "name",
        },
        {
          title: "أسم المستخدم",
          key: "email",
        },
        {
          title: "الراتب الحالي",
          key: "crruent_salary",
        },
      ];
    },

    financialFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "مقدار الصرفيات",
          key: "amount",
        },
        {
          title: "الملاحظات",
          key: "notes",
        },
        {
          title: "تاريخ الصرف",
          key: "created_at",
        },
      ];
    },
  },

  methods: {
    filtering() {
      if (this.filterData.date.length) {
        this.filterData.date_from = this.filterData.date[0];
        this.filterData.date_to = this.filterData.date[1];
        // TODO: delete it after it made nullable from backend
        if (this.filterData.date_from == null ) {
          this.filterData.date_from = "2010-01-01";
        }
        if (this.filterData.date_to == null) {
          this.filterData.date_to = "2150-01-01";
        }
      }
      this.getHabibiyaFinancials(this.filterData);
    },
  },
  mounted() {
    this.getHabibiyaFinancials();
  },
};
</script>
