<template>
  <div id="LoginPage" class="bg-main">
    <div class="container p-0 h-screen flex-center">
      <form action="" class="bg-gray-300 w-3/4 md:w-2/4 rounded-lg pt-3">
        <div class="form-head">
          <h1 class="text-center mb-3 font-bold text-xl">تسجيل الدخول</h1>
        </div>
        <div class="form-content bg-white rounded-lg py-3 px-10">
          <div class="input mb-3">
            <label for="" class="text-main block w-full font-bold">
              أسم المستخدم</label
            >
            <input
              type="text"
              placeholder="الاسم"
              v-model="postData.email"
              class="border-b-2 px-1 py-2 w-full text-sm outline-none"
            />
          </div>
          <div class="input mb-3">
            <label for="" class="text-main block w-full font-bold">
              رقم السري
            </label>
            <input
              type="رقم السري"
              placeholder="****"
              v-model="postData.password"
              class="border-b-2 px-1 py-2 w-full text-sm outline-none"
            />
          </div>
          <div class="controll">
            <button
              @click.prevent="post()"
              :class="[
                postLoader ? 'opacity-60 cursor-not-allowed' : '',
                'bg-main text-white font-bold w-full px-2 py-3',
              ]"
              :disabled="postLoader"
            >
              أرسال
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.avatar::before {
  content: "";
  display: block;
  background: #fff;
  opacity: 0.2;
  width: 300px;
  height: 300px;
  position: absolute;
  top: -60px;
  right: -60px;
  border-radius: 50%;
  z-index: -1;
}
.avatar::after {
  content: "";
  display: block;
  background: #fff;
  opacity: 0.2;
  width: 500px;
  height: 500px;
  position: absolute;
  bottom: -100px;
  left: -100px;
  border-radius: 50%;
  z-index: -1;
}
.avatar {
  border-radius: 0px 50px 50px 0px;
}
</style>
<script>
export default {
  data() {
    return {
      postData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    post() {
      this.login(this.postData).then(() => {
        if( this.postData.email == 'muser'){
          this.$router.push("/maintenance_orders");
        }
        else{
          this.$router.push("/orders");
        }
      });
    },
  },
  mounted() {
    if (localStorage.getItem("car_user_Info") !== null) {
      this.$router.push("/orders");
    }
  },
};
</script>
