import $http from "@/plugins/axios.js"

export default {
    state: {
        orders: [],
        orderStatisticsData: [],
        expansesData: [],
        orderStatistics: {},
        users: [],
        order: {
            cover: {},
            daino: {},
            jek: {},
            images: [],
            computer: [],
        },
        order_imgs: []
    },
    getters: {
        orders: state => state.orders,
        expansesData: state => state.expansesData,
        orderStatisticsData: state => state.orderStatisticsData,
        orderStatistics: state => state.orderStatistics,
        user: state => state.users,
        order: state => state.order
    },
    actions: {
        async getOrders({ commit }, filterData = { take: 15, page: 1, name: '', chassis_no: '', sanoia_no: '', creator_id: '', date_from:'',date_to:'' }) {
            const response = await $http.get(`/order?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}&date_from=${filterData.date_from}&date_to=${filterData.date_to}&filter[chassis_no]=${filterData.chassis_no}&filter[sanoia_no]=${filterData.sanoia_no}&filter[creator_id]=${filterData.creator_id}`)
            commit("SET_ORDERS", response.data);
        },
        async getOrderStatistics({ commit }, dateStatistics) {
            const response = await $http.get(`/statistcs?date=${dateStatistics}`)
            commit("SET_STATISTICS", response.data);
        },
        async getStatistics({ commit }, dateStatistics) {
            const response = await $http.get(`/orders-by-date?date=${dateStatistics}`)
            commit("SET_ORDERS_STATISTICS", response.data);
        },
        async getExpansesData({ commit }, dateStatistics) {
            const response = await $http.get(`/expenses-by-date?date=${dateStatistics}`)
            commit("SET_EXPANSES", response.data);
        },
        async getHabibiyaOrders({ commit }, filterData = { take: 15, page: 1, name: '', chassis_no: '', sanoia_no: '', creator_id: '', date_from:'2010-01-01',date_to:'2150-01-01' }) {
            const response = await $http.get(`/habibia/order-list?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}&date_from=${filterData.date_from}&date_to=${filterData.date_to}&filter[chassis_no]=${filterData.chassis_no}&filter[sanoia_no]=${filterData.sanoia_no}&filter[creator_id]=${filterData.creator_id}`)
            commit("SET_ORDERS", response.data);
        },
        async getHabibiyaMaintenanceOrders({ commit }, filterData = { take: 15, page: 1, name: '', chassis_no: '', sanoia_no: '', vehicle_no: '',created_at:'' }) {
            const response = await $http.get(`/habibia/order-maintenance-list?include=worker&take=${filterData.take}&page=${filterData.page}&filter[created_at]=${filterData.created_at}&filter[driver_name]=${filterData.name}&filter[vehicle_no]=${filterData.vehicle_no}&sort=status,-created_at`)
            commit("SET_ORDERS", response.data);
        },
        async getMaintenanceOrders({ commit }, filterData = { take: 15, page: 1, name: '', chassis_no: '', sanoia_no: '', vehicle_no: '',created_at:'' }) {
            const response = await $http.get(`/maintenance-order?include=worker&take=${filterData.take}&page=${filterData.page}&filter[created_at]=${filterData.created_at}&filter[driver_name]=${filterData.name}&filter[vehicle_no]=${filterData.vehicle_no}&sort=status,-created_at`)
            commit("SET_ORDERS", response.data);
        },
        async getDayleOrders({ commit }, filterData = { take: 15, page: 1, name: '', chassis_no: '', sanoia_no: '', creator_id: '' }) {
            const response = await $http.get(`/orders-today?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}&filter[chassis_no]=${filterData.chassis_no}&filter[sanoia_no]=${filterData.sanoia_no}&filter[creator_id]=${filterData.creator_id}&sort=-status`)
            commit("SET_ORDERS", response.data);
        },
        async getUsers({ commit }, filterData = { take: 100 }) {
            const response = await $http.get(`/user?take=${filterData.take}`)
            commit("SET_USERS", response.data);
        },

        async getOrder({ commit }, data) {
            const response = await $http.get(`/order/${data.id}?include=${data.include}`) //jek,daino,cover,images
            commit("SET_ORDER", response.data);
        },
        async getOrderMaintenance({ commit }, data) {
            const response = await $http.get(`/maintenance-order/${data.id}?include=${data.include}&sort=-created_at`)
            commit("SET_ORDER", response.data);
        },
        async getPublicOrder({ commit }, id) {
            const response = await $http.get(`/show/crc/get-order/${id}`) //jek,daino,cover,images
            commit("SET_ORDER", response.data);
        },

        async getCoverOrders({ commit }, filterData = { number: '', }) {
            const response = await $http.get(`/order-cover?filter[order_no]=${filterData.number}`)
            commit("SET_ORDERS", response.data);
        },

        async getCompletedOrders({ commit }, filterData = { take: 15, number: '', }) {
            const response = await $http.get(`/order-completed?take=${filterData.take}&filter[order_no]=${filterData.number}`)
            commit("SET_ORDERS", response.data);
        },

        async getCancelleddOrders({ commit }, filterData = { take: 15, number: '', }) {
            const response = await $http.get(`/order-cancelled?take=${filterData.take}&filter[order_no]=${filterData.number}`)
            commit("SET_ORDERS", response.data);
        },

        async createOrder({ commit }, order) {
            const response = await $http.post('/order', order)
            commit('ADD_NEW_ORDER', response.data.data);
            return response;
        },

        async createMaintenanceOrder({ commit }, order) {
            const response = await $http.post('/maintenance-order', order)
            commit('ADD_NEW_MAINTENANCE_ORDER', response.data.data)
            return response.data
        },

        async getOrderImage({ commit }, id) {
            await $http.get(`/order-image/${id}`)
            return commit
        },
        async createOrderImage({ commit }, order) {
            await $http.post('/order-image', order)
            return commit
        },
        async createMaintenanceOrderImage({ commit }, order) {
            await $http.post('/maintenanceOrderImage', order)
            return commit
        },
        async updateOrder({ commit }, order) {
            const response = await $http.post(`/order/${order.id}`, order)
            commit('UPDATE_ORDER', response.data.data)
        },
        async updateMaintenanceOrder({ commit }, order) {
            const response = await $http.post(`/maintenance-order/${order.id}`, order)
            commit('UPDATE_MAINTENANCE_ORDER', response.data.data)
        },
        async removeOrder({ commit }, id) {
            await $http.delete(`/order/${id}`)
            commit('REMOVE_ORDER', id)
        },
        async completeOrder({ commit }, id) {
            const response = await $http.put(`/order-complete/${id}`)
            commit('UPDATE_ORDER', response.data.data)
        },
        async completeMaintenanceOrder({ commit }, id) {
            const response = await $http.put(`/complete-order/${id}`)
            commit('COMPLETE_MAINTENANCE_ORDER', response.data.data)
        },
        async canceleOrder({ commit }, id) {
            const response = await $http.put(`/order-cancelled/${id}`)
            commit('UPDATE_MAINTENANCE_ORDER', response.data.data)
        },

        async changeStatusOrder({ commit }, data) {
            await $http.post(`/order-status/${data.order_id}`, data.data)
            return commit
        },
        async uploadImage({ commit }, data) {
            await $http.post(`/order-image/${data.id}`, data.image)
            return commit
        },
        async uploadMaintenanceImage({ commit }, data) {
            await $http.post(`/maintenanceOrderImage/${data.id}`, data.image)
            return commit
        },
        async removeImage({ commit }, id) {
            await $http.delete(`/order-image/${id}`)
            commit('REMOVE_ORDER_ORDER', id)
        },
        async removeMaintenanceImage({ commit }, id) {
            await $http.delete(`/maintenanceOrderImage/${id}`)
            commit('REMOVE_ORDER_ORDER', id)
        },
        async deleteOrder({ commit }, id) {
            await $http.delete(`/maintenance-order/${id}`)
            commit('REMOVE_ORDER', id)
        },
    },
    mutations: {
        SET_ORDERS(state, data) {
            state.orders = data
        },
        SET_STATISTICS(state, data) {
            state.orderStatistics = data
        },
        SET_ORDERS_STATISTICS(state, data) {
            state.orderStatisticsData = data
        },
        SET_EXPANSES(state, data) {
            state.expansesData = data
        },
        SET_Users(state, data) {
            state.users = data
        },
        SET_ORDER(state, data) {
            state.order = data
        },
        ADD_NEW_ORDER(state, data) {
            state.orders.data.push(data)
        },
        ADD_NEW_MAINTENANCE_ORDER(state, data) {
            state.orders.data.unshift(data)
        },
        COMPLETE_MAINTENANCE_ORDER(state, data) {
            state.orders.data = state.orders.data.map(item => item = item.id == data.id ? data : item)
        },
        UPDATE_MAINTENANCE_ORDER(state, data) {
            state.orders.data = state.orders.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_ORDER(state, id) {
            state.orders.data = state.orders.data.filter(item => item.id !== id)
        },
        REMOVE_ORDER_ORDER(state, id) {
            state.order.images = state.order.images.filter(item => item.id !== id)
        },

    }
}