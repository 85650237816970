<template>
  <div id="ServicesPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title=" الحسابات" icon="fas fa-calculator"></page-header>
    <div class="add-form -mt-10 bg-white mx-auto py-4 rounded shadow mb-3">
      <div class="container">
        <nav class="border-b text-sm flex justify-start">
          <span
            class="cursor-pointer text-center text-2xl inline-block w-1/3 px-4 py-2"
            v-on:click="toggleMainTabs(1)"
            v-bind:class="{
              'text-gray-700 hover:text-black': mainTab !== 1,
              'border-b-2 border-indigo-600 text-indigo-600 font-semibold': mainTab === 1,
            }"
            >الرواتب</span
          >
          <!-- active -->
          <span
            class="cursor-pointer text-center text-2xl inline-block w-1/3 px-4 py-2"
            v-on:click="toggleMainTabs(2)"
            v-bind:class="{
              'text-gray-700 hover:text-black': mainTab !== 2,
              'border-b-2 border-indigo-600 text-indigo-600 font-semibold': mainTab === 2,
            }"
            >الصرفيات</span
          >
          <span
            class="cursor-pointer text-center text-2xl inline-block px-4 py-2 w-1/3"
            v-on:click="toggleMainTabs(3)"
            v-bind:class="{
              'text-gray-700 hover:text-black': mainTab !== 3,
              'border-b-2 border-indigo-600 text-indigo-600 font-semibold': mainTab === 3,
            }"
            >السلف</span
          >
        </nav>
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
        >
          <div class="px-4 py-5 flex-auto">
            <div class="tab-content tab-space">
              <div v-bind:class="{ hidden: mainTab !== 1, block: mainTab === 1 }">
                <div class="flex flex-wrap mx-10 mt-8">
                  <div class="w-full">
                    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                      <li class="-mb-px cursor-pointer flex-1 text-center">
                        <a
                          class="text-l border border-main ml-1 font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(1)"
                          v-bind:class="{
                            'text-main bg-white': openTab !== 1,
                            'text-white bg-main': openTab === 1,
                          }"
                        >
                          رواتب الموظفين
                        </a>
                      </li>
                      <li class="-mb-px cursor-pointer flex-1 text-center">
                        <a
                          class="text-l border border-main ml-1 font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(2)"
                          v-bind:class="{
                            'text-main bg-white': openTab !== 2,
                            'text-white bg-main': openTab === 2,
                          }"
                        >
                          الخصومات
                        </a>
                      </li>
                      <li class="-mb-px cursor-pointer flex-1 text-center">
                        <a
                          class="text-l border border-main font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(3)"
                          v-bind:class="{
                            'text-main bg-white': openTab !== 3,
                            'text-white bg-main': openTab === 3,
                          }"
                        >
                          المكافات
                        </a>
                      </li>
                    </ul>
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-1 rounded"
                    >
                      <div class="px-4 py-5 flex-auto">
                        <div class="tab-content tab-space">
                          <div
                            v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
                          >
                            <div
                              class="add-form mt-1 bg-white w-full mx-auto py-4 rounded shadow mb-3"
                            >
                              <div class="container">
                                <div class="section-title">
                                  <span class="text-xl">أضافة راتب لموظف جديد</span>
                                </div>
                                <form
                                  action=""
                                  class="grid grid-cols-2 lg:grid-cols-4 gap-2"
                                >
                                  <div class="input mb-4">
                                    <label for="" class="requier">اختيار الموظف</label>
                                    <select
                                      name=""
                                      id=""
                                      v-model="postData.employee_id"
                                      class="field"
                                    >
                                      <option
                                        :value="item.id"
                                        v-for="(item, index) in employees.data"
                                        :key="index"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> الراتب </label>
                                    <input
                                      type="number"
                                      placeholder="راتب الموظف"
                                      v-model="postData.amount"
                                      class="field"
                                    />
                                  </div>
                                  <div class="mt-9 cursor-pointer">
                                    <span
                                      :class="[
                                        postLoader ? 'opacity-60 cursor-not-allowed' : '',
                                        'btn main-btn',
                                      ]"
                                      :disabled="postLoader"
                                      @click.prevent="addSalary()"
                                    >
                                      أضافة
                                    </span>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="">
                              <div class="container mt-10">
                                <div class="section-title">
                                  <span class="text-xl">قائمة رواتب الموظفين</span>
                                  <div class="show-per-Page text-sm font-normal">
                                    <span class=""> عناصر القائمة </span>
                                    <select
                                      name=""
                                      id=""
                                      v-model="filterData.take"
                                      class="bg-gray-100 px-2 rounded-md outline-none"
                                      @change="filtering"
                                    >
                                      <option :value="2">2</option>
                                      <option :value="15">15</option>
                                      <option :value="50">50</option>
                                      <option :value="100">100</option>
                                      <option :value="500">500</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="filters">
                                  <div class="name input">
                                    <input
                                      type="search"
                                      placeholder="اسم الموظف"
                                      class="field"
                                      v-model="filterData.name"
                                      @change="filtering" 
                                    />
                                  </div>
                                </div>
                                <div class="list relative py-5">
                                  <get-loader class="absolute inset-0"></get-loader>
                                  <data-table :fields="fields" :data="employees_salary.data">
                                    <template v-slot:order_no="{ item }">
                                      {{ item.order_no?.split("-")[4] }}
                                    </template>
                                    <template v-slot:created_at="{ item }">
                                      {{ getFormDate(item.created_at) }}
                                    </template>
                                    <template v-slot:updated_at="{ item }">
                                      {{ getFormDate(item.updated_at) }}
                                    </template>
                                    <template v-slot:actions="{ item }">
                                      <ul class="actions-list">
                                        <li
                                          @click="showSalaries(item.id)"
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="عرض الراتب"
                                          class="text-gray-500 hover:text-blue-500 hint--top"
                                        >
                                          <i class="fas fa-calculator"></i>
                                        </li>
                                      </ul>
                                    </template>
                                  </data-table>
                                </div>
                              </div>
                            </div>
                            <div
                              class="pagination mx-auto py-4 bg-white mt-3"
                              v-if="employees.total > filterData.take"
                            >
                              <div class="container flex items-center justify-center">
                                <v-pagination
                                  v-model="currentPageEmployees"
                                  :records="employees.total"
                                  :per-page="filterData.take"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
                          >
                            <div
                              class="add-form mt-1 bg-white w-full mx-auto py-4 rounded shadow mb-3"
                            >
                              <div class="container">
                                <div class="section-title">
                                  <span class="text-xl">أضافة خصم لموظف</span>
                                </div>
                                <form
                                  action=""
                                  class="grid grid-cols-2 lg:grid-cols-4 gap-2"
                                >
                                  <div class="input mb-4">
                                    <label for="" class="requier">اختيار الموظف</label>
                                    <select
                                      name=""
                                      id=""
                                      v-model="postPunishmentData.employee_id"
                                      class="field"
                                    >
                                      <option
                                        :value="item.id"
                                        v-for="(item, index) in employees.data"
                                        :key="index"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> مقدار الخصم </label>
                                    <input
                                      type="number"
                                      placeholder=" ادخل مقدار الخصم"
                                      v-model="postPunishmentData.amount"
                                      class="field"
                                    />
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> الملاحظات</label>
                                    <textarea
                                      rows="1"
                                      cols="4"
                                      placeholder="الملاحظات"
                                      v-model="postPunishmentData.notes"
                                      class="field"
                                    >
                                    </textarea>
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> التاريخ</label>
                                    <date-picker v-model="postPunishmentData.date" valueType="format"></date-picker>
                                  </div>
                                  <div class="mt-9 cursor-pointer">
                                    <span
                                      :class="[
                                        postLoader ? 'opacity-60 cursor-not-allowed' : '',
                                        'btn main-btn',
                                      ]"
                                      :disabled="postLoader"
                                      @click.prevent="addPunishment()"
                                    >
                                      أضافة
                                    </span>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="">
                              <div class="container mt-10">
                                <div class="section-title">
                                  <span class="text-xl">قائمة خصومات الموظفين</span>
                                  <div class="show-per-Page text-sm font-normal">
                                    <span class=""> عناصر القائمة </span>
                                    <select
                                      name=""
                                      id=""
                                      v-model="filterData.take"
                                      class="bg-gray-100 px-2 rounded-md outline-none"
                                      @change="filteringPunishments"
                                    >
                                      <option :value="2">2</option>
                                      <option :value="15">15</option>
                                      <option :value="50">50</option>
                                      <option :value="100">100</option>
                                      <option :value="500">500</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="filters">
                                  <div class="name input">
                                    <input
                                      type="search"
                                      placeholder="اسم الموظف"
                                      class="field"
                                      v-model="filterData.name"
                                      @change="filteringPunishments" 
                                    />
                                  </div>
                                </div>
                                <div class="list relative py-5">
                                  <get-loader class="absolute inset-0"></get-loader>
                                  <data-table
                                    :fields="punishmentFields"
                                    :data="punishments.data"
                                  >
                                    <template v-slot:order_no="{ item }">
                                      {{ item.order_no?.split("-")[4] }}
                                    </template>
                                    <template v-slot:name="{ item }">
                                      {{ item.employee.name }}
                                    </template>
                                    <template v-slot:created_at="{ item }">
                                      {{ getFormDate(item.date) }}
                                    </template>
                                    <template v-slot:updated_at="{ item }">
                                      {{ getFormDate(item.updated_at) }}
                                    </template>
                                    <template v-slot:actions="{ item }">
                                      <ul class="actions-list">
                                        <!-- <li
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="تعديل الراتب"
                                          class="text-gray-500 hover:text-blue-500 hint--top"
                                        >
                                          <i class="fas fa-pencil-alt"></i>
                                        </li> -->
                                        <li
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="مسح "
                                          class="text-gray-500 hover:text-red-500 hint--top"
                                          @click="delete_punishments(item)"
                                        >
                                          <i class="fas fa-times"></i>
                                        </li>
                                      </ul>
                                    </template>
                                  </data-table>
                                </div>
                              </div>
                            </div>
                            <div
                              class="pagination mx-auto py-4 bg-white mt-3"
                              v-if="punishments.total > filterData.take"
                            >
                              <div class="container flex items-center justify-center">
                                <v-pagination
                                  v-model="currentPagePunishment"
                                  :records="punishments.total"
                                  :per-page="filterData.take"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }"
                          >
                            <div
                              class="add-form mt-1 bg-white w-full mx-auto py-4 rounded shadow mb-3"
                            >
                              <div class="container">
                                <div class="section-title">
                                  <span class="text-xl">أضافة مكافاة لموظف</span>
                                </div>
                                <form
                                  action=""
                                  class="grid grid-cols-2 lg:grid-cols-4 gap-2"
                                >
                                  <div class="input mb-4">
                                    <label for="" class="requier">اختيار الموظف</label>
                                    <select
                                      name=""
                                      id=""
                                      v-model="postRewardsData.employee_id"
                                      class="field"
                                    >
                                      <option
                                        :value="item.id"
                                        v-for="(item, index) in employees.data"
                                        :key="index"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> مقدار المكافاة </label>
                                    <input
                                      type="number"
                                      placeholder=" ادخل مقدار المكافاة"
                                      v-model="postRewardsData.amount"
                                      class="field"
                                    />
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> الملاحظات</label>
                                    <textarea
                                      rows="1"
                                      cols="4"
                                      placeholder="الملاحظات"
                                      v-model="postRewardsData.notes"
                                      class="field"
                                    >
                                    </textarea>
                                    <!-- <input type="number" placeholder=" ادخل مقدار الخصم"
                                                                            v-model="postData.amount" class="field" /> -->
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> التاريخ</label>
                                    <date-picker v-model="postRewardsData.date" valueType="format"></date-picker>
                                  </div>
                                  <div class="mt-9 cursor-pointer">
                                    <span
                                      :class="[
                                        postLoader ? 'opacity-60 cursor-not-allowed' : '',
                                        'btn main-btn',
                                      ]"
                                      :disabled="postLoader"
                                      @click.prevent="addRewards()"
                                    >
                                      أضافة
                                    </span>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="">
                              <div class="container mt-10">
                                <div class="section-title">
                                  <span class="text-xl">قائمة مكافات الموظفين</span>
                                  <div class="show-per-Page text-sm font-normal">
                                    <span class=""> عناصر القائمة </span>
                                    <select
                                      name=""
                                      id=""
                                      v-model="filterData.take"
                                      class="bg-gray-100 px-2 rounded-md outline-none"
                                      @change="filteringRewards"
                                    >
                                      <option :value="2">2</option>
                                      <option :value="15">15</option>
                                      <option :value="50">50</option>
                                      <option :value="100">100</option>
                                      <option :value="500">500</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="filters">
                                  <div class="name input">
                                    <input
                                      type="search"
                                      placeholder="اسم الموظف"
                                      class="field"
                                      v-model="filterData.name"
                                      @change="filteringRewards"
                                    />
                                  </div>
                                </div>
                                <div class="list relative py-5">
                                  <get-loader class="absolute inset-0"></get-loader>
                                  <data-table
                                    :fields="rewardFields"
                                    :data="rewards.data"
                                  >
                                    <template v-slot:order_no="{ item }">
                                      {{ item.order_no?.split("-")[4] }}
                                    </template>
                                    <template v-slot:name="{ item }">
                                      {{ item.employee.name }}
                                    </template>
                                    <template v-slot:created_at="{ item }">
                                      {{ getFormDate(item.date) }}
                                    </template>


                                    <template v-slot:updated_at="{ item }">
                                      {{ getFormDate(item.updated_at) }}
                                    </template>
                                    <template v-slot:actions="{ item }">
                                      <ul class="actions-list">
                                        <!-- <li
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="تعديل الراتب"
                                          class="text-gray-500 hover:text-blue-500 hint--top"
                                        >
                                          <i class="fas fa-pencil-alt"></i>
                                        </li> -->
                                        <li
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="مسح "
                                          class="text-gray-500 hover:text-red-500 hint--top"
                                          @click="delete_rewards(item)"
                                        >
                                          <i class="fas fa-times"></i>
                                        </li>
                                      </ul>
                                    </template>
                                  </data-table>
                                </div>
                              </div>
                            </div>
                            <div
                              class="pagination mx-auto py-4 bg-white mt-3"
                              v-if="rewards.total > filterData.take"
                            >
                              <div class="container flex items-center justify-center">
                                <v-pagination
                                  v-model="currentPageRewards"
                                  :records="rewards.total"
                                  :per-page="filterData.take"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-bind:class="{ hidden: mainTab !== 2, block: mainTab === 2 }">
                <div
                  class="add-form mt-1 bg-white w-full mx-auto py-4 rounded shadow mb-3"
                >
                  <div class="container">
                    <div class="section-title">
                      <span class="text-xl">أضافة صرفيات جديدة</span>
                    </div>
                    <form action="" class="grid grid-cols-2 lg:grid-cols-4 gap-2">
                      <div class="input mb-4">
                        <label for="" class="requier">اختيار الموظف</label>
                        <select
                          name=""
                          id=""
                          v-model="postFinancialData.employee_id"
                          class="field"
                        >
                          <option
                            :value="item.id"
                            v-for="(item, index) in employees.data"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="input mb-4">
                        <label for="" class="requier">اختيار القسم</label>
                        <select
                          name=""
                          id=""
                          v-model="postFinancialData.department_id"
                          class="field"
                        >
                          <option
                            :value="item.id"
                            v-for="(item, index) in departments.data"
                            :key="index"
                          >
                            {{ item.ar_name }}
                          </option>
                        </select>
                      </div>
                      <div class="input mb-4">
                        <label for="" class="requier"> مقدار الصرف </label>
                        <input
                          type="number"
                          placeholder=" ادخل مقدار الصرف"
                          v-model="postFinancialData.amount"
                          class="field"
                        />
                      </div>
                      <div class="input mb-4">
                        <label for="" class="requier"> الملاحظات</label>
                        <textarea
                          rows="1"
                          cols="4"
                          placeholder="الملاحظات"
                          v-model="postFinancialData.notes"
                          class="field"
                        >
                        </textarea>
                        <!-- <input type="number" placeholder=" ادخل مقدار الخصم"v-model="postData.amount" class="field" /> -->
                      </div>
                      <div class="mt-9 cursor-pointer">
                        <span
                          :class="[
                            postLoader ? 'opacity-60 cursor-not-allowed' : '',
                            'btn main-btn',
                          ]"
                          :disabled="postLoader"
                          @click.prevent="addFinancial()"
                        >
                          أضافة
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="">
                  <div class="container mt-10">
                    <div class="section-title">
                      <span class="text-xl">قائمةالصرفيات</span>
                      <div class="show-per-Page text-sm font-normal">
                        <span class=""> عناصر القائمة </span>
                        <select
                          name=""
                          id=""
                          v-model="filterData.take"
                          class="bg-gray-100 px-2 rounded-md outline-none"
                          @change="filteringFinancials"

                        >
                          <option :value="2">2</option>
                          <option :value="15">15</option>
                          <option :value="50">50</option>
                          <option :value="100">100</option>
                          <option :value="500">500</option>
                        </select>
                      </div>
                    </div>
                    <div class="filters">
                      <div class="name input">
                        <input
                          type="search"
                          placeholder="اسم الموظف"
                          class="field"
                          v-model="filterData.name"
                        />
                      </div>
                    </div>
                    <div class="list relative py-5">
                      <get-loader class="absolute inset-0"></get-loader>
                      <data-table :fields="financialFields" :data="financials.data">
                        <template v-slot:order_no="{ item }">
                          {{ item.order_no?.split("-")[4] }}
                        </template>
                        <template v-slot:name="{ item }">
                          {{ item.employee.name }}
                        </template>
                        <template v-slot:created_at="{ item }">
                          {{ getFormDate(item.created_at) }}
                        </template>


                        <template v-slot:updated_at="{ item }">
                          {{ getFormDate(item.updated_at) }}
                        </template>
                        <template v-slot:actions="{ item }">
                          <ul class="actions-list">
                            <!-- <li
                              v-if="item.status !== 4 && item.status !== 5"
                              aria-label="تعديل الراتب"
                              class="text-gray-500 hover:text-blue-500 hint--top"
                            >
                              <i class="fas fa-pencil-alt"></i>
                            </li> -->
                            <li
                              v-if="item.status !== 4 && item.status !== 5"
                              aria-label="مسح "
                              class="text-gray-500 hover:text-red-500 hint--top"
                              @click="delete_financial(item)"

                            >
                              <i class="fas fa-times"></i>
                            </li>
                          </ul>
                        </template>
                      </data-table>
                    </div>
                  </div>
                </div>
                <div
                  class="pagination mx-auto py-4 bg-white mt-3"
                  v-if="financials.total > filterData.take"
                >
                  <div class="container flex items-center justify-center">
                    <v-pagination
                      v-model="currentPageFinancials"
                      :records="financials.total"
                      :per-page="filterData.take"
                    />
                  </div>
                </div>
              </div>
              <div v-bind:class="{ hidden: mainTab !== 3, block: mainTab === 3 }">
                <div class="flex flex-wrap mx-10 ">
                  <div class="w-full">
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
                    >
                      <div class="px-4 py-5 flex-auto">
                        <div class="tab-content tab-space">
                          <div
                            v-bind:class="{ hidden: loanTab !== 1, block: loanTab === 1 }"
                          >
                            <div
                              class="add-form mt-1 bg-white w-full mx-auto py-4 rounded shadow mb-3"
                            >
                              <div class="container">
                                <div class="section-title">
                                  <span class="text-xl">أضافة سلف جديدة</span>
                                </div>
                                <form
                                  action=""
                                  class="grid grid-cols-2 lg:grid-cols-4 gap-2"
                                >
                                  <div class="input mb-4">
                                    <label for="" class="requier">اختيار الموظف</label>
                                    <select
                                      name=""
                                      id=""
                                      v-model="postLoanData.employee_id"
                                      class="field"
                                    >
                                      <option
                                        :value="item.id"
                                        v-for="(item, index) in employees.data"
                                        :key="index"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> مقدار السلفة </label>
                                    <input
                                      type="number"
                                      placeholder=" ادخل مقدار السلفة"
                                      v-model="postLoanData.amount"
                                      class="field"
                                    />
                                  </div>
                                  <div class="input mb-4">
                                    <label for="" class="requier"> الملاحظات</label>
                                    <textarea
                                      rows="1"
                                      cols="4"
                                      placeholder="الملاحظات"
                                      v-model="postLoanData.notes"
                                      class="field"
                                    >
                                    </textarea>
                                    <!-- <input type="number" placeholder=" ادخل مقدار الخصم"v-model="postData.amount" class="field" /> -->
                                  </div>
                                  <div class="mt-9 cursor-pointer">
                                    <span
                                      :class="[
                                        postLoader ? 'opacity-60 cursor-not-allowed' : '',
                                        'btn main-btn',
                                      ]"
                                      :disabled="postLoader"
                                      @click.prevent="addLoan()"
                                    >
                                      أضافة
                                    </span>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="">
                              <div class="container mt-10">
                                <div class="section-title">
                                  <span class="text-xl">قائمة السلف</span>
                                  <div class="show-per-Page text-sm font-normal">
                                    <span class=""> عناصر القائمة </span>
                                    <select
                                      name=""
                                      id=""
                                      v-model="filterData.take"
                                      class="bg-gray-100 px-2 rounded-md outline-none"
                                      @change="filteringLoans"

                                    >
                                      <option :value="2">2</option>
                                      <option :value="15">15</option>
                                      <option :value="50">50</option>
                                      <option :value="100">100</option>
                                      <option :value="500">500</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="filters">
                                  <div class="name input">
                                    <input
                                      type="search"
                                      placeholder="اسم الموظف"
                                      class="field"
                                      v-model="filterData.name"
                                    />
                                  </div>
                                </div>
                                <div class="list relative py-5">
                                  <get-loader class="absolute inset-0"></get-loader>
                                  <data-table
                                    :fields="loanFields"
                                    :data="loans.data"
                                  >
                                    <template v-slot:order_no="{ item }">
                                      {{ item.order_no?.split("-")[4] }}
                                    </template>
                                    <template v-slot:name="{ item }">
                                      {{ item.employee.name }}
                                    </template>
                                    <template v-slot:created_at="{ item }">
                                      {{ getFormDate(item.created_at) }}
                                    </template>

                                    <template v-slot:updated_at="{ item }">
                                      {{ getFormDate(item.updated_at) }}
                                    </template>
                                    <template v-slot:actions="{ item }">
                                      <ul class="actions-list">
                                        <li
                                          @click="add_loan_repayment(item.id)"
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="تسديد دفعة السلفة "
                                          class="text-gray-500 hover:text-blue-500 hint--top"
                                        >
                                          <i class="fas fa-calculator"></i>
                                        </li>
                                        <!-- <li
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="تعديل "
                                          class="text-gray-500 hover:text-blue-500 hint--top"
                                        >
                                          <i class="fas fa-pencil-alt"></i>
                                        </li> -->
                                        <li
                                          v-if="item.status !== 4 && item.status !== 5"
                                          aria-label="مسح"
                                          class="text-gray-500 hover:text-red-500 hint--top"
                                          @click="delete_loans(item)"
                                        >
                                          <i class="fas fa-times"></i>
                                        </li>
                                      </ul>
                                    </template>
                                  </data-table>
                                </div>
                              </div>
                            </div>
                            <div
                              class="pagination mx-auto py-4 bg-white mt-3"
                              v-if="loans.total > filterData.take"
                            >
                              <div class="container flex items-center justify-center">
                                <v-pagination
                                  v-model="currentPageLoans"
                                  :records="loans.total"
                                  :per-page="filterData.take"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
export default {
  /* eslint-disable */
  components: { DatePicker },
  data() {
    return {
      date: null,
      currentPage: 1,
      currentPageEmployees: 1,
      currentPagePunishment:1,
      currentPageRewards:1,
      currentPageFinancials:1,
      currentPageLoans:1,
      postData: {
        amount: "",
        employee_id: "",
      },
      postPunishmentData: {
        amount: "",
        employee_id: "",
        notes: "",
        date:'',
      },
      postLoanData: {
        amount: "",
        employee_id: "",
        notes: "",
      },
      postLoanRepaymentData: {
        amount: "",
        employee_id: "",
        loan_id:'',
        notes: "",
      },
      postFinancialData: {
        amount: "",
        notes: "",
        employee_id: "",
        department_id: "",
      },
      postRewardsData: {
        amount: "",
        employee_id: "",
        notes: "",
        date:'',
      },
      openTab: 1,
      mainTab: 1,
      loanTab: 1,
      editData: {},
      filterData: {
        take: 15,
        page:1,
        skip: 0,
        name: "",
      },
    };
  },
  watch: {
    currentPageEmployees(value) {
      this.filterData.page = value;
      this.getEmployeesData(this.filterData);
    },
    currentPagePunishment(value) {
      this.filterData.page = value;
      this.getPunishments(this.filterData);
    },
    currentPageRewards(value) {
      this.filterData.page = value;
      this.getRewards(this.filterData);
    },
    currentPageFinancials(value) {
      this.filterData.page = value;
      this.getFinancials(this.filterData);
    },
    currentPageLoans(value) {
      this.filterData.page = value;
      this.getLoans(this.filterData);
    },
  },
  computed: {
    fields() {
      return [
        {
          title: "الاسم الكامل",
          key: "name",
        },
        {
          title: "أسم المستخدم",
          key: "email",
        },
        {
          title: "الراتب الحالي",
          key: "crruent_salary",
        },
      ];
    },
    punishmentFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "الخصم",
          key: "amount",
        },
        {
          title: "الملاحظات",
          key: "notes",
        },
        {
          title: "تاريخ الخصم",
          key: "created_at",
        },
      ];
    },
    rewardFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "المكافاة",
          key: "amount",
        },
        {
          title: "الملاحظات",
          key: "notes",
        },
        {
          title: "تاريخ المكافاة",
          key: "created_at",
        },
      ];
    },
    financialFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "مقدار الصرفيات",
          key: "amount",
        },
        {
          title: "الملاحظات",
          key: "notes",
        },
        {
          title: "تاريخ الصرف",
          key: "created_at",
        },
      ];
    },
    loanFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "المقدار المتبقي",
          key: "amount",
        },
        {
          title: "مقدار السلفة",
          key: "base_amount",
        },
        {
          title: "الملاحظات",
          key: "notes",
        },
        {
          title: "تاريخ انشاء السلفة",
          key: "created_at",
        },
      ];
    },
    loanRepaymentFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "مقدار دفعة السلفة",
          key: "amount",
        },
        {
          title: "تاريخ الدفعة",
          key: "created_at",
        },
      ];
    },
  },

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
      this.getEmployees();
      if (this.openTab == 1) {
        // this.getEmployees();
      } else if (this.openTab == 2) {
        this.getPunishments();
      } else if (this.openTab == 3) {
        this.getRewards();
      }
      //   else {
      //   }
    },
    toggleMainTabs: function (tabNumber) {
      this.mainTab = tabNumber;
      // if (this.mainTab == 1) {
      //   this.getFinancials();
      // } else
       if (this.mainTab == 2) {
        this.getDepartments();
        this.getFinancials();
      } 
      else if (this.mainTab == 3) {
        this.getLoans();
      }
    },
    toggleLoanTabs: function (tabNumber) {
      this.loanTab = tabNumber;
      if (this.loanTab == 1) {
        this.getLoans();
      } else if (this.loanTab == 2) {
        this.getLoansRepayments();
      }
    },
    delete_punishments(item) {
      this.confirmDelete(() => {
        this.deletePunishments(item.id);
      });
    },
    delete_financial(item) {
      this.confirmDelete(() => {
        this.deleteFinancials(item.id);
      });
    },
    delete_rewards(item) {
      this.confirmDelete(() => {
        this.deleteRewards(item.id);
      });
    },
    delete_loans(item) {
      this.confirmDelete(() => {
        this.deleteLoans(item.id);
      });
    },
    filtering() {
        this.getEmployeesData(this.filterData);
    },
    filteringPunishments() {
      this.getPunishments(this.filterData);
    },
    filteringRewards() {
      this.getRewards(this.filterData);
    },
    filteringFinancials() {
      this.getFinancials(this.filterData);
    },
    filteringLoans() {
      this.getLoans(this.filterData);
    },
    addSalary() {
      this.createSalary(this.postData).then(() => {
        this.getEmployees();
        this.postData = {
          amount: "",
          employee_id: "",
        };
      });
    },
    addPunishment() {
      this.createPunishments(this.postPunishmentData).then(() => {
        this.getPunishments();
        this.postPunishmentData = {
          amount: "",
          employee_id: "",
          notes: "",
        };
      });
    },
    addFinancial() {
      this.createFinancials(this.postFinancialData).then(() => {
        this.getFinancials();
        this.postFinancialData = {
          amount: "",
          employee_id: "",
          notes: "",
        };
      });
    },
    addLoan() {
      this.createLoans(this.postLoanData).then(() => {
        this.getLoans();
        this.postLoanData = {
          amount: "",
          employee_id: "",
          notes: "",
        };
      });
    },
    addLoanRepayment() {
      this.createLoansRepayments(this.postLoanRepaymentData).then(() => {
        this.getLoansRepayments();
        this.postLoanRepaymentData = {
          amount: "",
          employee_id: "",
          notes: "",
        };
      });
    },
    addRewards() {
      this.createRewards(this.postRewardsData).then(() => {
        this.getRewards();
        this.postRewardsData = {
          amount: "",
          employee_id: "",
          notes: "",
        };
      });
    },
    add_loan_repayment(id) {
      this.$router.push(`/loan-repayment/${id}`);
    },
    showSalaries(id) {
      console.log(id);
      this.$router.push(`/employee-salary/${id}`);
    },
  },
  mounted() {
    this.getEmployees();
    this.getEmployeesData();
    if(this.$route.params.tab == 3){
    this.toggleMainTabs(3);
    }else{
    this.toggleMainTabs(1);

    }
  },
};
</script>
