<template>
  <div id="sectionPrint">
    <div class="container">
      <invoice-header
        :orderInfo="data"
        :class="hidden ? 'print:flex hidden' : ''"
        class="mb-5"
      ></invoice-header>
      <div class="section-info">
        <div class="pt-8 mb-8">
          <div class="rounded-md p-2 border border-gray-300 relative text-sm">
            <div
              class="title font-bold mb-5 absolute -top-3 px-5 right-5 bg-indigo-50"
            >
              {{ sectionTitle }}
            </div>
            <div class="header grid grid-cols-3 gap-3">
              <img
                :src="img.image"
                v-for="(img, index) in data.images"
                :key="index"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import invoiceHeader from "./OrderInfo";

export default {
  props: {
    data: Object,
    sectionTitle: String,
    hidden: Boolean,
  },
  components: {
    invoiceHeader,
  },
};
</script>
