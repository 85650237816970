<template>
  <div id="printPage" class="w-10/12 bg-white mx-auto box-border relative">
    <get-loader class="absolute inset-0 z-10"></get-loader>
    <div
      class="print-btn w-16 h-16 rounded-full bg-main text-white text-2xl flex items-center justify-center fixed bottom-5 left-5 print:hidden cursor-default animate-bounce"
      @click="printPage()"
    >
      <i class="fas fa-print"></i>
    </div>
    <div
      class="water_mark fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-20 hidden print:block"
    >
      <img src="@/assets/logo_empty.png" class="w-60 z-40" alt="" />
    </div>
    <div class="order-container print:h-screen relative">
      <oreder-details :data="order"> </oreder-details>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد \ معارض النهضة \ الشارع الرئيسي \ خلف معرض اللامي </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
    <div
      class="jek-container print:h-screen relative"
      v-if="order.jek !== null"
    >
      <section-info
        :data="order"
        section="jek"
        :dataSection="jekInfo"
        sectionTitle="فحص الجــك"
        :hidden="true"
      ></section-info>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد \ معارض النهضة \ الشارع الرئيسي \ خلف معرض اللامي </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
    <div
      class="daino-container print:h-screen relative"
      v-if="order.daino !== null"
    >
      <section-info
        :data="order"
        :dataSection="dainoInfo"
        :hidden="true"
        section="daino"
        sectionTitle="فحص الداينو"
      ></section-info>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد \ معارض النهضة \ الشارع الرئيسي \ خلف معرض اللامي </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
    <div
      class="daino-container print:h-screen relative"
      v-if="order.computer !== null"
    >
      <computer-section
        :data="order"
        :dataSection="computerInfo"
        :hidden="true"
        section="computer"
        sectionTitle="فحص الكمبيوتر"
      ></computer-section>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد \ معارض النهضة \ الشارع الرئيسي \ خلف معرض اللامي </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
    <div
      class="cover-container print:h-screen relative"
      v-if="order.cover !== null"
    >
      <cover-section-info
        :data="order"
        :dataSectionFirst="coverInfoFirst"
        :dataSectionSecond="coverInfoSecond"
        :hidden="true"
        section="cover"
        sectionTitle="فحص البدي"
      ></cover-section-info>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد \ معارض النهضة \ الشارع الرئيسي \ خلف معرض اللامي </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
    <div
      class="image-container print:h-screen relative"
      v-if="order.images.length !== 0"
    >
      <image-section
        :data="order"
        :hidden="true"
        sectionTitle="صور المركبة"
      ></image-section>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد \ معارض النهضة \ الشارع الرئيسي \ خلف معرض اللامي </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media print {
  .jeck_info {
    height: 1400px;
  }
  .order-container,
  .jek-container,
  .daino-container,
  .cover-container,
  .image-container {
    height: calc(100vh - 20px);
    margin-top: 10px;
    @apply border-4 border-gray-500;
    page-break-after: always;
  }
}

.water_mark {
  z-index: 9999;
}
</style>
<script>
// import orderInfo from "@/components/Print/OrderInfo.vue";
import orederDetails from "@/components/Print/OrederDetails.vue";
import sectionInfo from "@/components/Print/SectionInfo.vue";
import coverSectionInfo from "@/components/Print/CoverSection.vue";
import computerSection from "@/components/Print/ComputerSection.vue";
import imageSection from "@/components/Print/ImageSection.vue";
export default {
  data() {
    return {
      jekInfo: [
        {
          title: "بوش",
          key: "bushes",
        },
        {
          title: "راس رواط",
          key: "head_of_roat",
        },
        {
          title: "شمعات صدر",
          key: "sham3at_sadder",
        },
        {
          title: "طبلة",
          key: "tabla",
        },
        {
          title: "التبريد",
          key: "cooling",
        },
        {
          title: "المحرك مفتوح ؟",
          key: "is_engine_open",
        },
        {
          title: "صوت في المحرك ؟",
          key: "sound_in_engine",
        },
        {
          title: "نضوح زيت في المحرك ؟",
          key: "oil_oozes_from_engine",
        },
        {
          title: "نضوح زيت في الكير ؟",
          key: "leaking_oil_from_gear",
        },
        {
          title: "نضوح ماء ؟",
          key: "leaking_water",
        },
        {
          title: "كيس المحرك مفتوح ؟",
          key: "is_kais_engine_open",
        },
        {
          title: "كيس الكير مفتوح ؟",
          key: "rabaat",
        },
        {
          title: "تايمن كيس مفتوح ؟",
          key: "is_taiman_kais_engine_open",
        },
        {
          title: "خشونة في المحرك ؟",
          key: "roughness_engine",
        },
      ],
      dainoInfo: [
        {
          title: "دبل",
          key: "dabbel",
        },
        {
          title: "عزم المحرك",
          key: "engine_torque",
        },
        {
          title: "عزم الكير",
          key: "gear_torque",
        },
        {
          title: "خلل الفور ويل",
          key: "issue_in_fourweel",
        },
        {
          title: "تأخر في نمرة الكبر ؟",
          key: "delay_in_nemra",
        },
        {
          title: "طبة في الكير ؟",
          key: "taba_in_gear",
        },
        {
          title: "بلنص ؟",
          key: "balance",
        },
      ],
      computerInfo: [
        {
          title: "منظومة الايرباك ",
          key: "air_bag",
        },
        // {
        //   title: "منظومة المحرك",
        //   key: "control_engine_ecm",
        // },
        // {
        //   title: "منظومة ناقل الحركه  ",
        //   key: "transmission_control_tcm",
        // },
        // {
        //   title: "منظومة الفرامل ومانع الانزلاق ",
        //   key: "anti_lock_brake_system",
        // },
        // {
        //   title: "منظومة الاستيرنج",
        //   key: "electric_power_steering",
        // },
        // {
        //   title: "منظومة الرادار ",
        //   key: "radar_control",
        // },
        // {
        //   title: "منظومة النقطه العمياء",
        //   key: "blind_spot_control",
        // },
      ],
      coverInfoFirst: [
        {
          title: "سقف",
          key: "roof",
        },
        {
          title: "غطاء المحرك",
          key: "hood",
        },

        {
          title: "باب ايسر أمامي",
          key: "left_door_front",
        },
        {
          title: "باب ايسر خلفي",
          key: "left_door_back",
        },

        {
          title: "باب أيمن خلفي",
          key: "right_door_back",
        },
        {
          title: "باب أيمن أمامي",
          key: "right_door_front",
        },

        {
          title: "تكمة ايسر أمامي",
          key: "left_front_techma",
        },
        {
          title: "تكمة ايسر خلفي",
          key: "left_back_techma",
        },
        {
          title: "تكمة أيمن أمامي",
          key: "right_front_techma",
        },
        {
          title: "تكمة أيمن خلفي",
          key: "right_back_techma",
        },

        {
          title: "الجنطة",
          key: "Boot",
        },
      ],
      coverInfoSecond: [
        {
          title: "جاملغ خلفي ايسر",
          key: "left_side_cover_back",
        },
        {
          title: "جاملغ خلفي أيمن",
          key: "right_side_cover_back",
        },
        {
          title: "جاملغ أمامي ايسر",
          key: "left_side_cover_front",
        },
        {
          title: "جاملغ أمامي أيمن",
          key: "right_side_cover_front",
        },

        {
          title: "دعامية أمامي",
          key: "front_bumper",
        },
        {
          title: "دعامية خلفي",
          key: "back_bumper",
        },

        {
          title: "زجاج خلفي",
          key: "windscreen_back",
        },
        {
          title: "زجاج امامي",
          key: "windscreen_front",
        },

        {
          title: "مرايا أيسر",
          key: "left_mirror",
        },
        {
          title: "مرايا ايمن",
          key: "right_mirror",
        },

        {
          title: "باب الجنطة",
          key: "Boot_door",
        },
      ],
    };
  },
  methods: {
    printPage() {
      window.print();
    },
  },
  computed: {
    getQrValue() {
      return `https://print.crc-almandelawi.com/${this.$route.params.id}`;
    },
  },
  components: {
    sectionInfo,
    coverSectionInfo,
    imageSection,
    computerSection,
    // orderInfo,
    orederDetails,
  },
  mounted() {
    this.getOrder({
      id: this.$route.params.id,
      include: "jek,daino,cover,images,computer",
    });
  },
};
</script>
